import { useComponentDefaultProps } from "@mantine/core";
import { useEffect, useRef } from "react";
import { GoogleMapRegionSelectorDefaultProps, GoogleMapRegionSelectorProps, GoogleMapZoomFeature, SelectedRegionData } from "./GoogleMapRegionSelectorTypes";
import { useGoogleMapRegionSelector } from "./useGoogleMapRegionSelector";



export function GoogleMapRegionSelector(props: GoogleMapRegionSelectorProps) {
    const mergedProps = useComponentDefaultProps("GoogleMapRegionSelector", GoogleMapRegionSelectorDefaultProps, props);

    const { featureTypeMap, showOnlyCurrentLayerFeatures, selectedRegions } = mergedProps;

    const regionSelectorAPI = useGoogleMapRegionSelector(mergedProps);

    const {
        map, currentZoomFeature, applyStyle, handleClick, handleMouseMove, setCurrentZoomFeature, setActiveZoomFeatures,
        refreshStyles
    } = regionSelectorAPI;

    // Layers
    useEffect(() => {
        if (!map) return;

        const featureType = currentZoomFeature.featureType;

        if (showOnlyCurrentLayerFeatures) {
            // Disable all layers except the current one
            const allFeatureTypes = Object.values(featureTypeMap!);

            allFeatureTypes.forEach(ft => {
                if (ft.featureType === currentZoomFeature?.featureType) return;
                const layer = map.getFeatureLayer(ft.featureType);
                if (layer) {
                    layer.style = null;
                }
            });
        }
        else {
            const currentLevel = currentZoomFeature.zoomLevel;
            const mediumLayer = map.getFeatureLayer(featureTypeMap!.medium.featureType);
            const highLayer = map.getFeatureLayer(featureTypeMap!.high.featureType);

            // Disable levels below the current one
            if (currentLevel === "low") {
                mediumLayer.style = null;
                highLayer.style = null;
            }
            else if (currentLevel === "medium") {
                highLayer.style = null;
            }

        }

        const featureLayer = map.getFeatureLayer(featureType!);
        if (featureLayer) {
            featureLayer.style = applyStyle;

            const clickListener = featureLayer.addListener("click", handleClick);
            const moveListener = featureLayer.addListener("mousemove", handleMouseMove);

            return () => {
                clickListener.remove();
                moveListener.remove();
            };
        } else {
            console.warn(`Feature layer '${featureType}' not found.`);
        }

    }, [applyStyle, currentZoomFeature.featureType, currentZoomFeature.zoomLevel, featureTypeMap, handleClick, handleMouseMove, map, showOnlyCurrentLayerFeatures]);

    // Zoom
    useEffect(() => {
        if (!map) return;

        const handleZoomChange = () => {
            const zoom = map.getZoom();

            if (zoom === undefined || featureTypeMap === undefined) return;
            let newFeatureType: GoogleMapZoomFeature;

            if (zoom <= featureTypeMap.low.zoom) {
                newFeatureType = featureTypeMap.low;
                setActiveZoomFeatures([featureTypeMap.low]);
            } else if (zoom > featureTypeMap.low.zoom && zoom <= featureTypeMap.medium.zoom) {
                newFeatureType = featureTypeMap.medium;
                if (showOnlyCurrentLayerFeatures) {
                    setActiveZoomFeatures([featureTypeMap.medium]);
                }
                else {
                    setActiveZoomFeatures([featureTypeMap.low, featureTypeMap.medium]);
                }
            } else {
                newFeatureType = featureTypeMap.high;
                if (showOnlyCurrentLayerFeatures) {
                    setActiveZoomFeatures([featureTypeMap.high]);
                }
                else {
                    setActiveZoomFeatures([featureTypeMap.low, featureTypeMap.medium, featureTypeMap.high]);
                }
            }

            setCurrentZoomFeature(newFeatureType);
        };

        const zoomListener = map.addListener("zoom_changed", handleZoomChange);

        // Initialize zoom level
        handleZoomChange();

        return () => {
            google.maps.event.removeListener(zoomListener);
        };
    }, [featureTypeMap, map, setActiveZoomFeatures, setCurrentZoomFeature, showOnlyCurrentLayerFeatures]);


    const prevSelectedRegionsRef = useRef<Record<string, SelectedRegionData>>({});

    // Handle deletes
    useEffect(() => {
        const prevSelectedRegions = prevSelectedRegionsRef.current;
        const currentKeys = Object.keys(selectedRegions);
        const prevKeys = Object.keys(prevSelectedRegions);

        if (currentKeys.length < prevKeys.length) {
            refreshStyles();
        }

        prevSelectedRegionsRef.current = selectedRegions;
    }, [selectedRegions, refreshStyles]);

    return null; // Not a visual React component
}
