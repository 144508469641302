import { useComponentDefaultProps } from "@mantine/core";
import { useCallback, useRef, useState } from "react";
import { GoogleMapRegionSelectorDefaultProps, GoogleMapRegionSelectorProps, GoogleMapZoomFeature, SelectedRegionData } from "./GoogleMapRegionSelectorTypes";
import { GoogleMapZoomFeatureStyles } from "./GoogleMapRegionsSelectorStyles";
import { GoogleMapsErrorStatus } from "./types";
import { useGoogleMap } from "./useGoogleMap";


export function useGoogleMapRegionSelector(props: GoogleMapRegionSelectorProps) {
    const {
        featureTypeMap, onSelectionChanged,
        onRegionClicked, onRegionMouseMove, onAPIError, selectedRegions, setSelectedRegions, googleMapsAPI,
        countries, showOnlyCurrentLayerFeatures,
    } = useComponentDefaultProps("GoogleMapRegionSelector", GoogleMapRegionSelectorDefaultProps, props);

    const { getPlaceDetails, placesReady } = googleMapsAPI;

    const { map } = useGoogleMap();

    const lastInteractedFeatureIdsRef = useRef<string[]>([]);

    const [currentZoomFeature, setCurrentZoomFeature] = useState<GoogleMapZoomFeature>(featureTypeMap!.low);
    const [activeZoomFeatures, setActiveZoomFeatures] = useState<GoogleMapZoomFeature[]>([featureTypeMap!.low]);

    const applyStyle = useCallback((params: google.maps.FeatureStyleFunctionOptions): google.maps.FeatureStyleOptions => {
        const feature = params.feature as google.maps.PlaceFeature;
        const placeId = feature.placeId;

        const featureType = feature.featureType as google.maps.FeatureType;
        const currentLevel = currentZoomFeature.zoomLevel;

        let styles: GoogleMapZoomFeatureStyles | undefined;

        if (currentLevel === 'low') {
            if (featureType === featureTypeMap!.low.featureType) {
                styles = featureTypeMap!.low.styles;
            }
        } else if (currentLevel === 'medium') {
            if (featureType === featureTypeMap!.low.featureType) {
                styles = featureTypeMap!.low.styles;
            } else if (featureType === featureTypeMap!.medium.featureType) {
                styles = featureTypeMap!.medium.styles;
            }
        } else if (currentLevel === 'high') {
            if (featureType === featureTypeMap!.low.featureType) {
                styles = featureTypeMap!.low.styles;
            } else if (featureType === featureTypeMap!.medium.featureType) {
                styles = featureTypeMap!.medium.styles;
            } else if (featureType === featureTypeMap!.high.featureType) {
                styles = featureTypeMap!.high.styles;
            }
        }

        if (!styles) {
            return {};
        }

        if (!placeId) return styles.styleDefault!;

        if (selectedRegions[placeId]) {
            return styles.styleClicked!;
        }

        if (lastInteractedFeatureIdsRef.current.includes(placeId)) {
            return styles.styleMouseMove!;
        }

        return styles.styleDefault!;

    }, [currentZoomFeature.zoomLevel, featureTypeMap, selectedRegions]);

    const highLightFeature = useCallback((placeIds: string[]) => {
        if (!map) return;

        lastInteractedFeatureIdsRef.current = placeIds;

        const featureType = currentZoomFeature?.featureType;
        const featureLayer = map.getFeatureLayer(featureType!);
        if (featureLayer) {
            featureLayer.style = applyStyle;
        }

    }, [applyStyle, currentZoomFeature?.featureType, map]);

    const refreshStyles = useCallback(() => {
        if (!map || !featureTypeMap) return;

        if (!showOnlyCurrentLayerFeatures) {
            const currentLevel = currentZoomFeature.zoomLevel;
            const mediumLayer = map.getFeatureLayer(featureTypeMap!.medium.featureType);
            const highLayer = map.getFeatureLayer(featureTypeMap!.high.featureType);

            // Disable levels below the current one
            if (currentLevel === "low") {
                if (mediumLayer !== null) mediumLayer.style = null;
                if (highLayer !== null) highLayer.style = null;

            }
            else if (currentLevel === "medium") {
                if (highLayer !== null) highLayer.style = null;
            }

            map.getFeatureLayer(featureTypeMap.low.featureType).style = applyStyle;
            map.getFeatureLayer(featureTypeMap.medium.featureType).style = applyStyle;
            map.getFeatureLayer(featureTypeMap.high.featureType).style = applyStyle;

        } else {
            // Only show current layer
            map.getFeatureLayer(currentZoomFeature.featureType).style = applyStyle;
        }
    }, [applyStyle, currentZoomFeature.featureType, currentZoomFeature.zoomLevel, featureTypeMap, map, showOnlyCurrentLayerFeatures]);

    const selectFeature = useCallback((features: google.maps.PlaceFeature[]) => {
        if (!map) return;

        if (!placesReady) {
            if (onAPIError) {
                const errorStatus: GoogleMapsErrorStatus = { status: 'PLACES_API_NOT_READY', origin: "Places" };
                onAPIError(errorStatus);
            }
            return;
        }

        // Copy the current selection, we will use it to add the placeId or delete it from the selection
        const internal_selection = { ...selectedRegions };

        for (const feature of features) {

            if (internal_selection[feature.placeId]) {
                // Deselect region
                delete internal_selection[feature.placeId];
            } else {
                // Add region with minimal data, before waiting for the promise
                const regionData: SelectedRegionData = { placeId: feature.placeId, featureType: feature.featureType };
                internal_selection[feature.placeId] = regionData;

                // Fetch addressComponents in the background
                getPlaceDetails({
                    placeId: feature.placeId,
                    fields: ['address_components'],
                }).then((place) => {

                    const addressComponents = place.address_components || [];
                    const countryComponent = addressComponents.find(comp => comp.types.includes('country'));
                    const adminLevel1Component = addressComponents.find(comp => comp.types.includes('administrative_area_level_1'));
                    const adminLevel2Component = addressComponents.find(comp => comp.types.includes('administrative_area_level_2'));
                    const postalCodeComponent = addressComponents.find(comp => comp.types.includes('postal_code'));

                    if (countries && countryComponent && !countries.includes(countryComponent.short_name!)) {
                        setSelectedRegions((prev) => {
                            const updated_selection = { ...prev };
                            delete updated_selection[feature.placeId];
                            return updated_selection;
                        });
                    }
                    else {
                        setSelectedRegions((prev) => {
                            // Update the selected region with addressComponents.
                            const updated_selection = { ...prev };
                            const new_region_data: SelectedRegionData = {
                                placeId: feature.placeId,
                                featureType: feature.featureType,
                                countryId: countryComponent?.short_name,
                                administrativeAreaLevel1Name: adminLevel1Component?.long_name,
                                administrativeAreaLevel1Id: adminLevel1Component?.short_name,
                                administrativeAreaLevel2Name: adminLevel2Component?.long_name,
                                administrativeAreaLevel2Id: adminLevel2Component?.short_name,
                                postalCode: postalCodeComponent?.short_name,
                            };

                            updated_selection[feature.placeId] =  new_region_data;

                            return updated_selection;
                        });
                    }


                }).catch((error) => {
                    if (onAPIError) {
                        const errorStatus: GoogleMapsErrorStatus = { status: 'APIERROR', origin: "Places" };
                        if (onAPIError) onAPIError(errorStatus);
                    }
                    else {
                        console.error("Error fetching place details:", error);
                    }
                });
            }
        }

        lastInteractedFeatureIdsRef.current = [];

        if (onSelectionChanged) {
            onSelectionChanged(internal_selection);
        }

        setSelectedRegions(internal_selection);

        refreshStyles();

    }, [countries, getPlaceDetails, map, onAPIError, onSelectionChanged, placesReady, refreshStyles, selectedRegions, setSelectedRegions]);


    const handleClick = useCallback((e: google.maps.FeatureMouseEvent) => {
        e.stop();
        if (!map) return;
        if (!e.features || e.features.length === 0) return;

        selectFeature(e.features as google.maps.PlaceFeature[]);


        if (onRegionClicked) {
            const placeId = (e.features[0] as google.maps.PlaceFeature).placeId;
            onRegionClicked(placeId);
        }

    }, [map, onRegionClicked, selectFeature]);


    // Mouse move over region
    const handleMouseMove = useCallback((e: google.maps.FeatureMouseEvent) => {
        if (!map) return;
        if (!e.features || e.features.length === 0) return;

        const placeIds = e.features.map((f) => (f as google.maps.PlaceFeature).placeId);

        highLightFeature(placeIds);

        if (onRegionMouseMove) {
            onRegionMouseMove(placeIds[0]);
        }

    }, [highLightFeature, map, onRegionMouseMove]);

    return {
        applyStyle,
        selectFeature,
        highLightFeature,
        currentZoomFeature,
        setCurrentZoomFeature,
        map,
        handleClick,
        handleMouseMove,
        activeZoomFeatures,
        setActiveZoomFeatures,
        refreshStyles
    }

}

