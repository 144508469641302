import { Text } from "@mantine/core";
import { Entity, EntityDefinition } from "../../Entity";
import { DBStatusResult, OperationStatus } from "../../client";
import { MicroMModalSize, ModalContextType } from "./ModalsManager";
import { createEntityForm } from "./createEntityForm";
import { FormMode } from "./types";


export interface openEntityFormProps {
    modals: ModalContextType,
    entity: Entity<EntityDefinition>,
    initialFormMode: FormMode,
    title?: string,
    element?: HTMLElement,
    getDataOnInit?: boolean
    handleModalSaved: (newStatus: OperationStatus<DBStatusResult | null>) => Promise<void>,
    handleModalCancel: () => Promise<void>,
    modalFormSize?: MicroMModalSize
}

export async function openEntityForm({
    modals, entity, initialFormMode, title, element, getDataOnInit, handleModalCancel, handleModalSaved, modalFormSize
}: openEntityFormProps) {

    const showOK = initialFormMode !== 'view';
    const onSaved = initialFormMode !== 'view' ? (new_status: OperationStatus<DBStatusResult | null>) => handleModalSaved(new_status) : () => Promise.resolve();
    const onCancel = () => handleModalCancel();
    const entity_form = createEntityForm({ entity, initialFormMode, getDataOnInit, showOK, onSaved, onCancel });

    await modals.open({
        modalProps: {
            title: <Text fw="700">{title} {entity.Title}</Text>,
            size: modalFormSize
        },
        focusOnClosed: element,
        content: entity_form
    });

}