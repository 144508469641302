import { useCallback, useEffect, useRef, useState } from "react";
import { EntityClientAction } from "../../Entity";
import { DBStatusResult, DataResult, OperationStatus, Value, ValuesObject, ValuesRecord } from "../../client";
import { useEntityUI } from "../Core";
import { GridColumn, GridDoubleClickCallback, GridSelection, GridSelectionChangedCallback } from "../Grid";
import { DataGridProps, DataGridSelectionKeys } from "./DataGrid";

export interface DataGridStateProps {
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>,
    setSearchText: React.Dispatch<React.SetStateAction<string[] | undefined>>,
    executeViewState: OperationStatus<DataResult[]>
}

export function useDataGrid(props: DataGridProps, stateProps: DataGridStateProps) {
    const {
        entity, parentKeys, viewName, onSelectionChanged, modalFormSize,
        labels, saveFormBeforeAdd, parentFormAPI, allwaysRefreshOnEntityClose, onAddClick, onModalSaved,
        onDataRefresh, onActionExecuted
    } = props;

    const { setRefresh, setSearchText, executeViewState } = stateProps;

    const selection = useRef<GridSelection>([]);
    const selectionKeys = useRef<DataGridSelectionKeys>([]);

    const [selectedRowsCount, setSelectedRowsCount] = useState(0);

    const [toggleSelectable, setToggleSelectable] = useState(false);

    const [isLoading, setIsLoading] = useState(false); //este loading se quita solo cuando ya los datos fueron procesados y están en la grilla

    const [columns, setColumns] = useState<GridColumn[]>();
    const [rows, setRows] = useState<ValuesRecord[]>();


    const internalRefresh = useCallback(() => {
        setRefresh((prev) => !prev);
    }, [setRefresh]);

    const handleRefresh = useCallback((search_text: string[] | undefined) => {
        setSearchText(search_text);
        setRefresh((prev) => !prev);
    }, [setRefresh, setSearchText]);

    const handleModalSaved = useCallback(async (new_status: OperationStatus<DBStatusResult | null>) => {
        if (!new_status.error && !new_status.loading && new_status.data) internalRefresh();
        if (onModalSaved) await onModalSaved(new_status);
    }, [internalRefresh, onModalSaved]);

    const handleModalCancel = useCallback(async () => {
        if (allwaysRefreshOnEntityClose) await internalRefresh();
    }, [allwaysRefreshOnEntityClose, internalRefresh]);

    const UIAPI = useEntityUI({
        entity, parentKeys, modalFormSize, parentFormAPI, saveFormBeforeAdd, onModalSaved: handleModalSaved, onModalCancelled: handleModalCancel,
        onRecordsDeleted: internalRefresh, onActionRefreshOnClose: internalRefresh, labels, onAddClick, onActionExecuted
    });


    const getSelectionKeys = useCallback((selection: GridSelection) => {
        const result: DataGridSelectionKeys = [];
        if (entity && viewName) {
            for (let i = 0; i < selection.length; i++) {
                const record = selection[i];
                const keys: ValuesObject = {};
                const record_properties = Object.keys(record);
                const key_mappings = entity.def.views[viewName].keyMappings;
                for (const columnName in key_mappings) {
                    const idx = key_mappings[columnName];
                    if (idx >= record_properties.length) continue;
                    keys[columnName] = record[record_properties[idx]] as Value;
                }
                if (keys) result.push(keys);
            }
        }
        return result;
    }, [entity, viewName]);


    const handleToggleSelectable = useCallback(() => {
        setToggleSelectable(prev => !prev);
    }, []);

    const handleSelectionChanged = useCallback<GridSelectionChangedCallback>(async (newSelection) => {
        selection.current = newSelection;
        selectionKeys.current = getSelectionKeys(newSelection);
        setSelectedRowsCount(newSelection.length);
        if (onSelectionChanged) onSelectionChanged(newSelection, selectionKeys.current);
    }, [onSelectionChanged, getSelectionKeys]);


    const handleEditClick = useCallback(async (element?: HTMLElement) => {
        if (entity?.Form === null) return;
        const keys = selectionKeys.current;
        if (keys.length) {
            await UIAPI.handleEditClick(keys[0], element);
        }
    }, [entity?.Form, UIAPI]);

    const handleViewClick = useCallback(async (element?: HTMLElement) => {
        if (entity?.Form === null) return;
        const keys = selectionKeys.current;
        if (keys.length) {
            await UIAPI.handleViewClick(keys[0], element);
        }
    }, [entity?.Form, UIAPI]);

    const handleDeleteClick = useCallback(async (element?: HTMLElement) => {
        const keys = selectionKeys.current;
        await UIAPI.handleDeleteClick(keys, element);
    }, [UIAPI]);

    const handleExecuteAction = useCallback(async (action: EntityClientAction, element?: HTMLElement) => {
        const keys = selectionKeys.current;
        await UIAPI.handleExecuteAction(action, keys, element);
    }, [UIAPI]);

    const handleDoubleClick = useCallback<GridDoubleClickCallback>(async () => {
        await handleEditClick();
    }, [handleEditClick]);

    // MMC: this is special here for the Grid component as it uses columns and records
    const handleExport = useCallback(() => {

        if (!rows?.length || !columns?.length) return;

        //TODO: copiado de MCSI.Web viejo, revisar

        const fechaActual = new Date();
        const filename = `export-${fechaActual.getFullYear()}${(fechaActual.getDate() + '').padStart(2, '0')}${(fechaActual.getMonth() + 1 + '').padStart(2, '0')}_${(fechaActual.getHours() + '').padStart(2, '0')}${(fechaActual.getMinutes() + '').padStart(2, '0')}${(fechaActual.getSeconds() + '').padStart(2, '0')}.csv`;

        const row_sep = '\r\n';
        const col_sep = ';';
        let csv_data = '';

        for (let r = 0; r < columns.length; r++) {
            if (columns[r].hidden === true) continue;
            csv_data += '"' + columns[r].text.replace(/"/g, '""') + '"' + col_sep;
        }
        csv_data += row_sep;
        for (let r = 0; r < rows.length; r++) {
            for (let c = 0; c < columns.length; c++) {
                if (columns[c].hidden === true) continue;
                const col_data = rows[r][c];
                if (typeof col_data === 'string' || col_data instanceof String) col_data.replace(/"/g, '""');
                csv_data += '"' + col_data + '"' + col_sep;
            }
            csv_data += row_sep;
        }

        // MMC: \ufeff = BOM para utf8. Sin esto excel no interpreta correctamente el archivo.
        csv_data = '\ufeff' + csv_data;
        // MMC: varias configuraciones de seguridad pueden causar una excepcion
        try {
            if (window.Blob && window.URL) {
                const blob = new Blob([csv_data], {
                    type: 'text/csv;charset=utf-8'
                });
                const csv_url = URL.createObjectURL(blob);
                const linkElement = document.createElement('a');
                linkElement.setAttribute('download', filename);
                linkElement.setAttribute('href', csv_url);
                linkElement.click();
            }
        } catch (ex) {
            try {
                const msSaveBlob = (window.navigator as unknown as { msSaveBlob: (...args: unknown[]) => void }/*evita warn no existe*/).msSaveBlob;
                if (msSaveBlob) {
                    const blob = new Blob([decodeURIComponent(csv_data)], {
                        type: 'text/csv;charset=utf8'
                    });
                    // Crashes in IE 10, IE 11 and Microsoft Edge
                    // See MS Edge Issue #10396033
                    msSaveBlob(blob, filename);
                } else {
                    const encoded_data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_data);
                    const linkElement = document.createElement('a');
                    linkElement.setAttribute('download', filename);
                    linkElement.setAttribute('href', encoded_data);
                    linkElement.click();
                }
            } catch (ex2) {
                alert("Your browser does not support exporting data.");
            }
        }
    }, [columns, rows]);

    useEffect(() => {
        if (executeViewState.loading) {
            setIsLoading(true);
        } else if (executeViewState.error) {
            setIsLoading(false);
        } else if (!executeViewState.error && executeViewState.data?.length) {
            setColumns(executeViewState.data[0].Header.map((columnText, index) => ({
                field: index.toString(),
                text: columnText,
                sqlType: executeViewState.data![0].typeInfo[index]
            })));
            setRows(executeViewState.data[0].records);
            setIsLoading(false);
            if (onDataRefresh) onDataRefresh();
        }
    }, [executeViewState.data, executeViewState.error, executeViewState.loading, onDataRefresh]);


    return {
        handleSelectionChanged,
        handleDoubleClick,
        handleDeleteClick,
        handleEditClick,
        handleAddClick: UIAPI.handleAddClick,
        handleViewClick,
        handleToggleSelectable,
        handleRefresh,
        handleExecuteAction,
        handleExport,
        selectedRowsCount,
        showSelectCheckbox: toggleSelectable,
        UIAPI,
        columns,
        rows,
        isLoading
    }
}
