import { Anchor, Button, Checkbox, Group, PasswordInput, TextInput, useComponentDefaultProps } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback, useState } from "react";
import { MicroMClient, MicroMToken, OperationStatus, StatusCompletedHandler, toMicroMError } from "../../client";
import { AlertError, FakeProgressBar } from "../Core";

export interface LoginOptions {
    client: MicroMClient,
    onStatusCompleted: StatusCompletedHandler<MicroMToken>,
    userLabel?: string,
    userPlaceholder?: string,
    passwordLabel?: string,
    passwordPlaceholder?: string,
    rememberLabel?: string,
    forgotLabel?: string,
    signInButtonLabel?: string,
    loginErrorMessage?: string
}

export const LoginDefaultProps: Partial<LoginOptions> = {
    userLabel: "User",
    userPlaceholder: "your@email.com",
    passwordLabel: "Password",
    passwordPlaceholder: "Your password",
    rememberLabel: "Remember me",
    forgotLabel: "Forgot password?",
    signInButtonLabel: "Sign in",
    loginErrorMessage: "Unknown user name or bad password"

}

export interface LoginValues { user: string, password: string, server: string }

export function Login(props: LoginOptions) {
    const { client, onStatusCompleted, userLabel, userPlaceholder, passwordLabel, passwordPlaceholder,
        rememberLabel, forgotLabel, signInButtonLabel, loginErrorMessage } = useComponentDefaultProps('Login', LoginDefaultProps, props);
    const form = useForm<LoginValues>(
        {
            initialValues: {
                user: '',
                password: '',
                server: ''
            }
        });

    const [status, setStatus] = useState<OperationStatus<MicroMToken>>();

    const handleClick = useCallback(async (values: LoginValues) => {
        setStatus({ loading: true });
        try {
            const data = await client.login(values.user, values.password);
            const new_status = { data: data };
            setStatus(new_status);
            onStatusCompleted(new_status);
        }
        catch (e) {
            const new_status = { error: toMicroMError(e) };
            setStatus(new_status);
            onStatusCompleted(new_status);
        }
    }, [client, form.values, onStatusCompleted]);

    return (
        <>
            <form onSubmit={form.onSubmit((values) => handleClick(values))}>
                {status?.loading && <FakeProgressBar />}
                <TextInput label={userLabel} placeholder={userPlaceholder} required data-autofocus disabled={status?.loading} {...form.getInputProps('user')} />
                <PasswordInput label={passwordLabel} placeholder={passwordPlaceholder} required mt="md" disabled={status?.loading}  {...form.getInputProps('password')} />
                <Group position="apart" mt="lg">
                    <Checkbox label={rememberLabel} />
                    <Anchor component="button" size="sm">
                        {forgotLabel}
                    </Anchor>
                </Group>
                <Button fullWidth mt="xl" disabled={status?.loading} type="submit">
                    {signInButtonLabel}
                </Button>
            </form>
            <AlertError mt="xs" hidden={!status?.error}>{loginErrorMessage}</AlertError>
        </>
    );
}