import { useCallback, useEffect, useRef, useState } from "react";
import { Entity, EntityDefinition, EntityProc, areValuesObjectsEqual } from "../../Entity";
import { DBStatus, DBStatusResult, OperationStatus, ValuesObject, toDBStatusMicroMError, toMicroMError } from "../../client";

export function useExecuteProcess(entity: Entity<EntityDefinition>, proc: EntityProc) {
    const [status, setStatus] = useState<OperationStatus<DBStatusResult>>({ loading: false, operationType: 'proc' });
    const cancellation = useRef<AbortController>(new AbortController());
    const done = useRef<boolean>(false);
    const prevValues = useRef<ValuesObject | undefined>();

    useEffect(() => {
        cancellation.current = new AbortController();
        return () => {
            if (!done.current) {
                console.log("useExecuteProcess aborted");
                cancellation.current?.abort("Effect cleanup");
            }
        };
    }, []);


    const execute = useCallback(async (values?: ValuesObject) => {
        if (status.loading) return status;

        if (
            !areValuesObjectsEqual(values, prevValues.current)
        ) {

            try {
                if (entity && proc) {
                    setStatus({ loading: true, operationType: 'proc' });

                    const data = await entity.API.executeProcess(proc, values, cancellation.current.signal);
                    done.current = true;
                    const new_status: OperationStatus<DBStatusResult> = { data: data, operationType: 'proc' };
                    setStatus(new_status);
                    return new_status;
                }

                // Update previous values
                prevValues.current = values;

            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            catch (e: any) {
                if (e.name !== 'AbortError') {
                    const new_status: OperationStatus<DBStatusResult> = { error: e.Errors ? toDBStatusMicroMError(e.Errors as DBStatus[], 'add') : toMicroMError(e), operationType: 'proc' };
                    setStatus(new_status);
                    return new_status;
                }
                else {
                    const new_status: OperationStatus<DBStatusResult> = { loading: false, operationType: 'proc' };
                    setStatus(new_status);
                    return new_status;
                }
            }

        }
    }, [entity, proc, status]);

    return {
        execute: execute,
        status: status
    }
}
