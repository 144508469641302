import { ActionIcon, Badge, Group, MantineNumberSize, Notification, SelectItem, rem, useComponentDefaultProps, useMantineTheme } from "@mantine/core";
import { IconCheckbox, IconDownload, IconFilter, IconReload, IconX } from "@tabler/icons-react";
import { EntityConstructor } from "../../Entity";
import { MicroMClient, ValuesObject } from "../../client";
import { ActionIconVariant, SearchFilterInput, ToggleActionIcon } from "../Core";
import { getToolbarSizes } from "./ToolBarFunctions";
import { useDataGridToolbarFilters } from "./useDataGridToolbarFilters";

export type DataGridToolbarSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface DataGridToolbarOptions {
    size?: DataGridToolbarSizes,
    client?: MicroMClient,
    searchPlaceholder?: string,
    hideCheckboxToggle?: boolean,
    onCheckboxToggle?: () => void,
    onSearchTextChange?: (text: string[] | undefined) => void,
    onRefreshClick: (searchText: string[] | undefined) => void,
    onExportClick: () => void,
    autoFocus?: boolean,
    toolbarIconVariant?: ActionIconVariant,
    enableExport?: boolean,
    refreshTooltip?: string,
    exportTooltip?: string,
    selectRowsTooltip?: string,
    searchText: string[] | undefined,
    setSearchText: React.Dispatch<React.SetStateAction<string[] | undefined>>,
    searchData: SelectItem[],
    setSearchData: React.Dispatch<React.SetStateAction<SelectItem[]>>,

    FiltersEntity?: EntityConstructor,
    filterTooltip?: string,
    parentKeys?: ValuesObject,
    filterValues: ValuesObject | undefined,
    setFilterValues: React.Dispatch<React.SetStateAction<ValuesObject | undefined>>,
    clearFiltersTooltip?: string,
    filtersFormSize: MantineNumberSize
}

export const DataGridToolbarDefaultProps: Partial<DataGridToolbarOptions> = {
    size: "sm",
    hideCheckboxToggle: false,
    toolbarIconVariant: "light",
    enableExport: true,
    searchPlaceholder: "Enter search text",
    refreshTooltip: "Refresh",
    exportTooltip: "Export",
    selectRowsTooltip: "Select items",
    filterTooltip: "Filter items",
    clearFiltersTooltip: "Clear filters",
}


export function DataGridToolbar(props: DataGridToolbarOptions) {

    const {
        size, hideCheckboxToggle, onCheckboxToggle,
        onSearchTextChange, onRefreshClick, autoFocus,
        toolbarIconVariant, onExportClick, enableExport,
        searchPlaceholder, refreshTooltip, exportTooltip, selectRowsTooltip,
        searchData, setSearchData, searchText, setSearchText, filterTooltip, parentKeys,
        setFilterValues, filterValues, clearFiltersTooltip, filtersFormSize, FiltersEntity, client
    } = useComponentDefaultProps('DataGridToolbar', DataGridToolbarDefaultProps, props);

    const theme = useMantineTheme();

    const { buttonsSize, actionIconSize, iconsSize } = getToolbarSizes(size!);

    const filtersAPI = useDataGridToolbarFilters({
        filterValues, setFilterValues, client: client, parentKeys, FiltersEntity, setSearchData, setSearchText, onRefreshClick, searchData, onSearchTextChange,
        filtersFormSize,
    });

    return (
        <>
            <Group>
                <ActionIcon
                    title={refreshTooltip}
                    onClick={() => {
                        if (onRefreshClick) onRefreshClick(searchText)
                    }}
                    size={actionIconSize}
                    radius="xl"
                    color={theme.primaryColor}
                    variant={toolbarIconVariant} >
                    <IconReload size={iconsSize} stroke="1.5" />
                </ActionIcon>
                <SearchFilterInput placeholder={searchPlaceholder}
                    onKeyDown={(e: React.KeyboardEvent) => filtersAPI.handleSearchFilterInputEnter(e)}

                    data={searchData}
                    value={searchText}
                    searchValue={filtersAPI.queryText}

                    onSearchClick={() => onRefreshClick(searchText)}
                    onChange={filtersAPI.handleSearchFilterInputOnChange}
                    onSearchChange={(text) => filtersAPI.setQueryText(text)}
                    onCreate={filtersAPI.createSearchPhrase}

                    clearSearchOnChange

                    size={buttonsSize}
                    iconsSize={iconsSize}
                    autoFocus={autoFocus}
                    iconVariant={toolbarIconVariant}
                    sx={{ flexGrow: 1 }}
                />
                {FiltersEntity &&
                    <>
                        {filtersAPI.filtersDescription && Object.keys(filtersAPI.filtersDescription).length > 0 &&
                            <Badge title={filterTooltip} pl={0} radius="xl" size="lg" maw="20rem" fz="xs" color="green.5"
                                styles={{ root: { textTransform: 'unset', fontWeight: 400 }, inner: { color: theme.colors.green[5] } }}
                                leftSection={
                                    <ActionIcon onClick={filtersAPI.handleFilterButtonClick} size={actionIconSize} radius="xl" color="green.5" variant={toolbarIconVariant}>
                                        <IconFilter size={iconsSize} stroke="1.5" color={theme.colors.green[5]} />
                                    </ActionIcon>
                                }
                                rightSection={
                                    <ActionIcon size="xs" color="green.5" radius="xl" variant="transparent" onClick={filtersAPI.handleClearFiltersClick}>
                                        <IconX size={rem(10)} />
                                    </ActionIcon>}
                            >
                                ({Object.keys(filtersAPI.filtersDescription).length})
                            </Badge>
                        }
                        {(!filtersAPI.filtersDescription || (filtersAPI.filtersDescription && Object.keys(filtersAPI.filtersDescription).length === 0)) &&
                            <ActionIcon title={filterTooltip} onClick={filtersAPI.handleFilterButtonClick} size={actionIconSize} radius="xl" color={theme.primaryColor} variant={toolbarIconVariant}>
                                <IconFilter size={iconsSize} stroke="1.5" />
                            </ActionIcon>
                        }
                    </>
                }
                <ToggleActionIcon
                    title={selectRowsTooltip}
                    hidden={hideCheckboxToggle ? true : false}
                    onClick={onCheckboxToggle}
                    size={actionIconSize}
                    offColor={theme.primaryColor}
                    onColor="green.5"
                    offVariant={toolbarIconVariant}
                    onVariant={toolbarIconVariant}
                    onIcon={<IconCheckbox size={iconsSize} stroke="1.5" />}
                    offIcon={<IconCheckbox size={iconsSize} stroke="1.5" />}
                />
                {enableExport &&
                    <ActionIcon
                        title={exportTooltip}
                        onClick={() => {
                            if (onExportClick) onExportClick()
                        }} size={actionIconSize} radius="xl" color={theme.primaryColor} variant={toolbarIconVariant} ><IconDownload size={iconsSize} stroke="1.5" /></ActionIcon>
                }
            </Group>
            {FiltersEntity && filtersAPI.filtersDescription && Object.keys(filtersAPI.filtersDescription).length > 0 &&
                <Notification
                    mt="sm"
                    withBorder
                    icon={<IconFilter size={iconsSize} stroke="1.5" />}
                    color="teal"
                    closeButtonProps={{ title: clearFiltersTooltip }}
                    onClose={filtersAPI.handleClearFiltersClick}>
                    <Group spacing="xs" style={{ gap: '0.5rem' }}>
                        {Object.keys(filtersAPI.filtersDescription).map((filter, index) =>
                            <Badge key={index} radius="xl" size="md" maw="20rem" styles={{ root: { textTransform: 'unset' } }} color="teal">
                                {filter}{filtersAPI.filtersDescription?.[filter] ? `: ${filtersAPI.filtersDescription?.[filter]?.toString()}` : ''}
                            </Badge>
                        )}
                    </Group>
                </Notification>
            }
        </>
    );
}